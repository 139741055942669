import React, { useEffect, useState } from 'react'
import { graphql, navigate } from 'gatsby'
import Modal from 'react-modal'
import { Seo } from '../components/base'
import {
  LayoutBackground,
  BioFullWidthHeader,
  BioBookmarks
} from '../components/shared'
import { Layout } from '../components'
import { getPageBuilderComponents } from '../components/base/PageBuilderComponents'
import { isLunchAvailable, marketPath } from '../utils/'
import { getLocaleMarket } from '../utils/getLocaleMarket'
import {
  getLocale,
  getLocaleMarketUrl,
  getLocaleUrl
} from '../utils/localeUtils'

Modal.setAppElement('#___gatsby')

const MarketPage = ({ data, location, pageContext }) => {
  const {
    name,
    address: { city },
    address: { street },
    image
  } = data.sanityMarketData

  const [lunchNotAvailable, setLunchNotAvailable] = useState(false)
  const myMarket = getLocaleMarket()
  const locale = getLocale()

  useEffect(() => {
    const isBrowser = () => typeof window !== 'undefined'
    if (isBrowser()) {
      const isExternalReferrer =
        document.referrer ||
        !document.referrer.includes(window.location.hostname)
      const noMarketInUrl = window.location.pathname.split('/').length == 2
      if (
        (myMarket &&
          !location.pathname.includes(myMarket?.slug) &&
          !isExternalReferrer) ||
        (myMarket && noMarketInUrl)
      ) {
        navigate(
          getLocaleMarketUrl(
            locale,
            myMarket?.countryCode,
            marketPath(myMarket)
          )
        )
      }
      // else if (!myMarket) navigate(getLocaleUrl(locale, '/'))
    }
  }, [locale])

  const seoTitle = `${name}, ${street}, ${city}`
  let market = data.sanityMarketData
  const marketLabels = data.marketPage.nodes[0].marketLabels
  const { seo, pageBuilder, heroImage, buttonWithMarket, buttonWithoutMarket } =
    data.marketPage.nodes[0]
  let marketNotifications = data.sanityMarketData.announcement
  const groupNotifications = data.marketNotifications?.nodes

  const hasLunch = isLunchAvailable(
    data.allSanityLunchOffer?.nodes[0],
    data.nextWeekOffer?.nodes[0]
  )

  useEffect(() => {
    if (!hasLunch) {
      setLunchNotAvailable(true)
    }
  })

  const marketIntro = data?.sanityMarketData?.marketIntro?._rawIntroText
  const languages = { pageContext }

  return (
    <Layout
      currentMarket={market}
      isHeroTeaser={true}
      isMarketPage={true}
      fullBackground={true}
      translations={languages}>
      <Seo
        title={seoTitle}
        description={seo?.metaDescription}
        image={
          market?.image?.image?.asset?.url
            ? market.image.image.asset.url
            : seo?.ogImage?.asset?.url
        }
        market={market}
        location={location}
      />
      <BioFullWidthHeader
        isMarketPage={true}
        previewImage={heroImage}
        isFullWidth={true}
        buttonWithMarket={buttonWithMarket}
        buttonWithoutMarket={buttonWithoutMarket}
        marketImage={image}
        market={market}
        marketLabels={marketLabels}
        marketNotifications={marketNotifications}
        groupNotifications={groupNotifications}
      />
      <BioBookmarks
        lunchNotAvailable={lunchNotAvailable}
        marketIntro={marketIntro}
      />
      <LayoutBackground isMarketPage={true}>
        {pageBuilder.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {getPageBuilderComponents(item, myMarket, false, { ...data })}
            </React.Fragment>
          )
        })}
      </LayoutBackground>
    </Layout>
  )
}

export default MarketPage

export const something = graphql`
  query (
    $marketId: String!
    $dateToday: Date!
    $calendarWeek: Float!
    $nextWeek: Float!
    $land: String!
    $language: String!
  ) {
    marketPage: allSanityMarketPage(filter: { i18n_lang: { eq: $language } }) {
      nodes {
        title
        subtitle
        seo {
          ...SeoQuery
        }
        heroImage {
          ...ImageObjectQuery
        }
        marketInfoHeadline
        openingHoursLabel
        marketLabels {
          servicesLabel
          servicesLabelSubline
          assortmentLabel
          customerCardLabel
          equipmentLabel
          localSupplierLabel
          parkingLabel
          zeroWasteLabel
        }
        buttonWithMarket {
          ...ButtonQuery
        }
        buttonWithoutMarket {
          ...ButtonQuery
        }
        ...MarketPageBuilderElements
      }
    }
    marketNotifications: allSanityMarketNotification(
      filter: {
        marketGroup: {
          elemMatch: { market: { elemMatch: { marketId: { eq: $marketId } } } }
        }
      }
    ) {
      nodes {
        ...GroupNotificationQuery
      }
    }
    sanityOfferPage {
      overviewSublineMarket
      legalDisclaimer
      overviewHeadline
    }
    sanityMarketData: sanityMarket(marketId: { eq: $marketId }) {
      ...MarketQuery
    }
    sanityMarketOffers: allSanityOffer(
      filter: {
        marketIdList: { marketIds: { in: [$marketId] } }
        marketHighlight: { eq: true }
        validfrom: { lte: $dateToday }
        validto: { gte: $dateToday }
        land: { eq: $land }
      }
      sort: {
        fields: [
          customCardColor___order
          articleGroup___productGroup___order
          title
          brand
        ]
      }
      limit: 6
    ) {
      nodes {
        ...OfferQuery
      }
    }

    eightPagerGroup: sanityMarketGroup(title: { eq: "8-Seiter" }) {
      market {
        marketId
      }
    }

    sanityMarketOffersGlobal: allSanityOffer(
      filter: {
        global: { eq: true }
        marketHighlight: { eq: true }
        validfrom: { lte: $dateToday }
        validto: { gte: $dateToday }
        land: { eq: $land }
      }
      sort: {
        fields: [
          customCardColor___order
          articleGroup___productGroup___order
          title
          brand
        ]
      }
      limit: 6
    ) {
      nodes {
        ...OfferQuery
      }
    }
    bmvOnlyOffers: allSanityOffer(
      filter: {
        marketHighlight: { eq: true }
        validfrom: { lte: $dateToday }
        validto: { gte: $dateToday }
        land: { eq: $land }
        bmvOnly: { eq: true }
      }
      sort: {
        fields: [
          customCardColor___order
          articleGroup___productGroup___order
          title
          brand
        ]
      }
      limit: 3
    ) {
      nodes {
        ...OfferQuery
      }
      totalCount
    }
    sanityLunchPage: allSanityLunchPage(
      filter: { i18n_lang: { eq: $language } }
    ) {
      nodes {
        ...LunchPageQuery
      }
    }
    allSanityLunchOffer(
      filter: { week: { eq: $calendarWeek }, marketId: { eq: $marketId } }
    ) {
      nodes {
        ...LunchOfferQuery
      }
    }
    nextWeekOffer: allSanityLunchOffer(
      filter: { week: { eq: $nextWeek }, marketId: { eq: $marketId } }
    ) {
      nodes {
        ...LunchOfferQuery
      }
    }
    groupEvents: allSanityBioMarketEvents(
      filter: {
        eventTeaser: {
          marketReference: {
            marketGroup: {
              elemMatch: {
                market: { elemMatch: { marketId: { eq: $marketId } } }
              }
            }
          }
        }
      }
    ) {
      nodes {
        eventTeaser {
          ...EventQuery
        }
      }
    }
    singleEvents: allSanityBioMarketEvents(
      filter: {
        eventTeaser: {
          marketReference: {
            referenceMarket: { elemMatch: { marketId: { eq: $marketId } } }
          }
        }
      }
    ) {
      nodes {
        eventTeaser {
          ...EventQuery
        }
      }
    }
  }
`
